<template>
  <div>
    <a-row :gutter="[12, 12]">
      <a-col :span="24" style="width: 256px">
        <a-input v-model="searchForm.search" placeholder="名称, 备注" allowClear @pressEnter="search" />
      </a-col>
      <a-col :span="24" style="width: 256px">
        <a-select v-model="searchForm.system" placeholder="系统" allowClear style="width: 100%" @change="search">
          <a-select-option key="test_wms" value="test_wms">WMS 测试环境</a-select-option>
          <a-select-option key="test_erp" value="test_erp">ERP 测试环境</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="24" style="width: 256px">
        <UserSelect v-model="searchForm.creator" placeholder="创建人" @change="search" />
      </a-col>
      <a-col :span="24" style="width: 100px">
        <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
      </a-col>
      <a-col :span="24" style="width: 100px; float: right">
        <a-button type="primary" icon="plus" style="width: 100%" @click="createModalVisible = true">新增</a-button>
      </a-col>

      <a-col :span="24">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="dataItems"
          :loading="dataLoading"
          :pagination="pagination"
          @change="changeTable"
        >
          <template slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? "激活" : "冻结" }}</a-tag>
          </template>

          <template slot="action" slot-scope="value, item">
            <table-action :item="item" @update="update" @destroy="destroy" />
          </template>
        </a-table>
      </a-col>
    </a-row>

    <create-form-modal v-model="createModalVisible" @create="create" @cancel="createModalVisible = false" />
  </div>
</template>

<script>
import { insertItem, replaceItem, removeItem } from "@/utils/functions";
import { accountList } from "@/apis/data";
import moment from "moment";

export default {
  components: {
    UserSelect: () => import("@/components/UserSelect"),
    CreateFormModal: () => import("./CreateFormModal"),
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "公司",
          dataIndex: "company",
        },
        {
          title: "用户名",
          dataIndex: "username",
        },
        {
          title: "系统",
          dataIndex: "system_display",
        },
        {
          title: "账号数量",
          dataIndex: "user_count",
        },
        {
          title: "续费周期",
          dataIndex: "renewal_period",
        },
        {
          title: "到期日期",
          dataIndex: "expiry_date",
        },
        {
          key: "remain_days",
          title: "剩余天数",
          dataIndex: "expiry_date",
          sorter: true,
          customRender: (value) => {
            const remain_days = moment(value).diff(moment(), "days");
            if (remain_days < 0) {
              return 0;
            }
            return remain_days;
          },
        },
        {
          title: "激活状态",
          dataIndex: "is_active",
          scopedSlots: { customRender: "is_active" },
        },
        {
          title: "创建人",
          dataIndex: "creator",
          customRender: (_, item) => item.creator_item.name,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 160,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],

      createModalVisible: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      accountList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    create(item) {
      this.dataItems = insertItem(this.dataItems, item);
    },
    update(item) {
      this.dataItems = replaceItem(this.dataItems, item);
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
